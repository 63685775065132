// Zendesk Chat Widget API
// https://api.zopim.com/files/meshim/widget/controllers/LiveChatAPI-js.html

export function hideChatWidget() {
  try {
    window.zE?.(() => {
      window.$zopim?.(() => {
        window.$zopim?.livechat.hideAll() // Hide the widget completely.
      })
    })
  } catch (err) {
    console.log(err)
  }
}

export function onChatStart(onStart: () => void) {
  try {
    window.zE?.('messenger:on', 'open', () => {
      onStart()
    })
  } catch (err) {
    console.log(err)
  }
}

export function setChatUserDetails({
  email,
  name,
  phone,
}: {
  email: string
  name: string
  phone: string | undefined
}) {
  try {
    window.zE?.(() => {
      window.$zopim?.(() => {
        window.$zopim?.livechat.setEmail(email)

        if (name) {
          window.$zopim?.livechat.setName(name)
        }

        if (phone) {
          window.$zopim?.livechat.setPhone(phone)
        }
      })
    })
  } catch (err) {
    console.log(err)
  }
}

export function showChatButton() {
  try {
    window.zE?.(() => {
      window.$zopim?.(() => {
        window.$zopim?.livechat.button.show() // Show the chat button.
      })
    })
  } catch (err) {
    console.log(err)
  }
}

export function showChatWindow() {
  try {
    window.zE?.(() => {
      window.$zopim?.(() => {
        window.$zopim?.livechat.window.show()
      })
    })
  } catch (err) {
    console.log(err)
  }
}

export function closeMessenger() {
  try {
    window.zE?.('messenger', 'close')
  } catch (err) {
    console.log(err)
  }
}

export function hideWebWidget() {
  try {
    window.zE?.('messenger', 'hide')
  } catch (err) {
    console.log(err)
  }
}

export function onMessengerClose(onClose: () => void) {
  try {
    window.zE?.('messenger:on', 'close', () => {
      onClose()
    })
  } catch (err) {
    console.log(err)
  }
}

export function onMessengerOpen(onOpen: () => void) {
  try {
    window.zE?.('messenger:on', 'open', () => {
      onOpen()
    })
  } catch (err) {
    console.log(err)
  }
}

export function openMessenger() {
  try {
    window.zE?.('messenger', 'open')
  } catch (err) {
    console.log(err)
  }
}

export function showWebWidget() {
  try {
    window.zE?.('messenger', 'show')
  } catch (err) {
    console.log(err)
  }
}
