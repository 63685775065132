import { Button, ButtonLoading } from '@tovala/component-library'
import { clsx } from 'clsx'
import { MouseEvent, useCallback, useState } from 'react'
import { useForm, UseFormHandleSubmit } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  UserSubscriptionType,
  UserV1,
  useUserSubscriptionTypes,
  useUserTermStatuses,
} from '@tovala/browser-apis-combinedapi'

import { DATE_FORMATS, formatDate } from 'utils/dates'
import {
  ErrorCodeMessageMapCombinedAPI,
  SubscriptionPreferences,
} from 'types/internal'
import { events, sourceIDs } from 'analytics/events'
import { formatCentsToDollars } from 'utils/currency'
import { getSubscriptionPreferences } from 'utils/subscriptions'
import { track } from 'utils/analytics'

import {
  useChangeSubscriptionStatus,
  useUpdatePlanPreferences,
} from 'hooks/combinedAPI/subscriptions'
import { useCustomer } from 'hooks/combinedAPI/customers'
import { useMealsSignUp } from 'hooks/mealsSignUp'
import { usePublicCurrentMenu } from 'hooks/menus'
import { useToast } from 'contexts/toast'
import { useTypeformPopup } from 'hooks/typeform'
import { useUser } from 'contexts/user'
import ActiveRewards from '../ActiveRewards'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Collapsible, {
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/common/Collapsible'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from 'components/common/ConfirmationDialog'
import DeliveryDays from '../DeliveryDays'
import ErrorDisplay from 'components/common/ErrorDisplay'
import Link from 'components/common/Link'
import MealPlans from '../MealPlans'
import PauseCancelConfirmation, {
  CancelFreeDessertBody,
  PauseCancelBody,
} from './PauseCancelConfirmation'

interface FormData {
  shipPeriod: string
  subscriptionTypeID: string
}

export type ConfirmationDialogData =
  | { isFreeDessertCustomer: boolean; type: 'cancel' }
  | { type: 'pause' }

const LOAD_MEAL_PLAN_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page.',
    why: "We couldn't load your current meal plan details due to a technical issue on our end.",
  },
}

const EditPlan = ({
  onClickSkipAFewDeliveries,
  onSubscriptionStatusChanged,
}: {
  onClickSkipAFewDeliveries(): void
  onSubscriptionStatusChanged(): void
}) => {
  const { user } = useUser()

  const {
    data: getUserSubscriptionTypesResponse,
    error: loadUserSubscriptionTypesError,
    isError: hasLoadUserSubscriptionTypesError,
  } = useUserSubscriptionTypes({
    userID: user.id,
  })
  const userSubscriptionTypes =
    getUserSubscriptionTypesResponse?.subscription_types ?? []

  return userSubscriptionTypes.length > 0 ? (
    <EditPlanLoaded
      onClickSkipAFewDeliveries={onClickSkipAFewDeliveries}
      onSubscriptionStatusChanged={onSubscriptionStatusChanged}
      user={user}
      userSubscriptionTypes={userSubscriptionTypes}
    />
  ) : hasLoadUserSubscriptionTypesError ? (
    <div className="md:p-4">
      <APIErrorDisplay
        error={loadUserSubscriptionTypesError}
        errorCodeMessageMap={LOAD_MEAL_PLAN_ERRORS}
      />
    </div>
  ) : null
}

export default EditPlan

const EditPlanLoaded = ({
  onClickSkipAFewDeliveries,
  onSubscriptionStatusChanged,
  user,
  userSubscriptionTypes,
}: {
  onClickSkipAFewDeliveries(): void
  onSubscriptionStatusChanged(): void
  user: UserV1
  userSubscriptionTypes: UserSubscriptionType[]
}) => {
  const location = useLocation()
  const { openToast } = useToast()

  const [confirmationDialogData, setConfirmationDialogData] =
    useState<ConfirmationDialogData | null>(null)

  const onCloseTypeformSurvey = useCallback(() => {
    openToast({
      heading: 'Changes Saved',
      message: `Your meal plan has been ${
        confirmationDialogData?.type === 'pause' ? 'paused' : 'canceled'
      }.`,
      type: 'success',
    })
  }, [openToast, confirmationDialogData])

  const { openTypeformPopup } = useTypeformPopup({
    onClose: onCloseTypeformSurvey,
    survey: confirmationDialogData?.type ?? 'pause',
    userID: `${user.id}`,
  })

  const planPreferences = getSubscriptionPreferences({ user })

  const isSubscriptionActive = user.subscription.status === 'active'

  const { data: termStatuses = [] } = useUserTermStatuses({ userID: user.id })

  const { term: publicCurrentTerm } = usePublicCurrentMenu({
    enabled: !isSubscriptionActive,
  })

  const { link } = useMealsSignUp({ utmSource: 'account' })

  const mealCommitmentPlanFlag = user.subscription.isCommitment
  const eightMealPlan = userSubscriptionTypes.find(
    (type) => type.max_selections === 8
  )

  const shippingAddress =
    user.shippingAddresses.length > 0 ? user.shippingAddresses[0] : undefined
  const shipPeriodInfo = shippingAddress?.shipPeriodInfo
  const availableShipPeriods =
    shipPeriodInfo
      ?.filter((shipPeriod) => shipPeriod.isAvailable)
      .sort((a, b) => a.shipPeriod - b.shipPeriod) ?? []
  const defaultShipPeriodForAddress = shipPeriodInfo?.find(
    (shipPeriod) => shipPeriod.isDefaultShipPeriodForAddress
  )?.shipPeriod
  const deliveryDayOptions = availableShipPeriods.map((shipPeriod) => ({
    label: `${shipPeriod.deliveryDayOfTheWeek}s`,
    value: shipPeriod.shipPeriod.toString(),
  }))

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      shipPeriod: planPreferences
        ? (
            planPreferences.defaultShipPeriod ||
            defaultShipPeriodForAddress ||
            1
          ).toString()
        : '',
      subscriptionTypeID:
        user.subscription.subscriptionType?.id ?? eightMealPlan?.id ?? '',
    },
  })

  const values = watch()

  let nextStartDate = ''
  let nextOrderByDate = ''
  if (!isSubscriptionActive && publicCurrentTerm) {
    nextStartDate = publicCurrentTerm.startDate
    nextOrderByDate = publicCurrentTerm.orderByDate
  } else if (isSubscriptionActive && termStatuses.length) {
    const nextTerm = termStatuses[0]
    nextStartDate = nextTerm.startDate
    nextOrderByDate = nextTerm.orderByDate
  }

  const planButtonText = isSubscriptionActive ? 'Edit Plan' : 'Browse Plans'

  const selectedSubscription = userSubscriptionTypes.find(
    (type) => type.id === values.subscriptionTypeID
  )
  const selectedShipPeriod =
    shipPeriodInfo &&
    shipPeriodInfo?.find(
      (shipPeriod) => shipPeriod.shipPeriod.toString() === values.shipPeriod
    )

  function onClosePauseCancelConfirmationModal() {
    setConfirmationDialogData(null)
  }

  if (link.type === 'external') {
    return (
      <div className="rounded-xl bg-grey-10 p-6 text-white md:hidden">
        <div className="flex justify-between">
          <h2 className="text-k/20_125 font-semibold">No Meal Plan</h2>
          <div>
            <Link
              linkStyle="white"
              linkType={link.type}
              size="small"
              to={link.to}
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <form>
      <div className="md-hidden">
        <Collapsible defaultOpen={location.pathname === '/account/edit-plan'}>
          {({ open }) => {
            return (
              <>
                <div
                  className={clsx(
                    'bg-grey-10 p-6 text-white md:hidden',
                    open ? 'rounded-t-xl' : 'rounded-xl'
                  )}
                >
                  <div className="flex justify-between">
                    <div className="pr-6">
                      <h2 className="mb-3 text-k/20_125 font-semibold">
                        {isSubscriptionActive || open ? (
                          <span>
                            {selectedSubscription?.max_selections} Meal Plan •{' '}
                            {selectedShipPeriod
                              ? selectedShipPeriod.deliveryDayOfTheWeek
                              : ''}{' '}
                            Deliveries
                          </span>
                        ) : (
                          <span>No Meal Plan</span>
                        )}
                      </h2>

                      {(isSubscriptionActive || open) &&
                        selectedSubscription && (
                          <div className="text-k/16_125">
                            <span className="border-r border-white pr-3">
                              Shipping:{' '}
                              {selectedSubscription.shippingCents > 0
                                ? `${formatCentsToDollars(
                                    selectedSubscription.shippingCents
                                  )}`
                                : 'Free'}
                            </span>

                            <span className="pl-3">
                              Estimated Weekly Total:{' '}
                              {formatCentsToDollars(
                                selectedSubscription.price_cents +
                                  selectedSubscription.shippingCents
                              )}
                            </span>
                          </div>
                        )}

                      {!isSubscriptionActive && nextStartDate && !open && (
                        <div className="text-k/16_125">
                          Restart now to get meals for the week of{' '}
                          {formatDate(nextStartDate, {
                            format: DATE_FORMATS.MONTH_FULL_DAY_SUFFIX,
                          })}
                        </div>
                      )}
                    </div>

                    <CollapsibleTrigger className="h-9 shrink-0 rounded-full border border-white px-8 text-sm">
                      <span>{open ? 'Close' : planButtonText}</span>
                    </CollapsibleTrigger>
                  </div>
                </div>

                <CollapsibleContent open={open}>
                  {({ close }) => {
                    return (
                      <>
                        <div className="bg-grey-2 p-6 md:bg-grey-0 md:p-4">
                          <h3 className="mb-5 text-k/20_125" id="delivery-day">
                            Delivery day
                          </h3>
                          {deliveryDayOptions && (
                            <div className="max-w-sm">
                              <div
                                aria-labelledby="delivery-day"
                                role="radiogroup"
                              >
                                <DeliveryDays
                                  control={control}
                                  deliveryOptions={deliveryDayOptions}
                                  name="shipPeriod"
                                />
                              </div>
                            </div>
                          )}

                          <h3 className="mb-5 mt-6 text-k/20_125" id="meals">
                            Meals per delivery
                          </h3>
                          <div
                            aria-labelledby="meals"
                            className="grid auto-rows-[88px] grid-cols-[repeat(auto-fill,78px)] gap-4"
                            role="radiogroup"
                          >
                            <MealPlans
                              cardStyle="white"
                              control={control}
                              mealPlans={userSubscriptionTypes}
                              name="subscriptionTypeID"
                            />
                          </div>

                          <div className="mt-6 max-w-md text-body-sm text-grey-9">
                            {!isDirty && isSubscriptionActive ? (
                              <p className="mb-4">
                                Editing your meal plan affects any deliveries
                                that haven't been processed, skipped, or had
                                their delivery size or day changed.
                              </p>
                            ) : selectedSubscription ? (
                              <>
                                {isSubscriptionActive && (
                                  <p className="mb-4">
                                    Your {selectedSubscription.name} will go
                                    into effect immediately and affects all
                                    weeks of meals that are not yet
                                    finalized/selected.
                                  </p>
                                )}
                                {nextOrderByDate && (
                                  <p className="mb-4">
                                    Your estimated weekly total will be{' '}
                                    {formatCentsToDollars(
                                      selectedSubscription.price_cents
                                    )}
                                    {selectedSubscription.shippingCents > 0 && (
                                      <span>
                                        {' '}
                                        +
                                        {formatCentsToDollars(
                                          selectedSubscription.shippingCents
                                        )}{' '}
                                        shipping
                                      </span>
                                    )}{' '}
                                    weekly, with the first billing{' '}
                                    {formatDate(nextOrderByDate, {
                                      format: DATE_FORMATS.DOW_FULL_MONTH_DAY,
                                    })}
                                    .
                                  </p>
                                )}

                                {isSubscriptionActive && (
                                  <p className="mb-6">
                                    You may have to edit any meal selections
                                    you've already made (but we have not yet
                                    finalized and billed you for) to make sense
                                    with your new plan. Please double check all
                                    weeks of editable meals.
                                  </p>
                                )}
                              </>
                            ) : null}
                          </div>

                          {isSubscriptionActive && (
                            <div className="space-y-6">
                              <div className="max-w-sm">
                                <ActiveRewards cardStyle="white" user={user} />
                              </div>

                              <div className="flex space-x-4 md:grid md:grid-cols-3 md:gap-2 md:space-x-0">
                                <Button
                                  onClick={() => {
                                    close()
                                    onClickSkipAFewDeliveries()
                                  }}
                                  size="small"
                                >
                                  Skip a Delivery
                                </Button>

                                {!mealCommitmentPlanFlag && (
                                  <Button
                                    buttonStyle="stroke"
                                    onClick={() => {
                                      setConfirmationDialogData({
                                        type: 'pause',
                                      })

                                      track(events.TAPS_PAUSE_MEAL_PLAN)
                                    }}
                                    size="small"
                                  >
                                    Pause Plan
                                  </Button>
                                )}

                                {!mealCommitmentPlanFlag && (
                                  <Button
                                    buttonStyle="stroke"
                                    onClick={() => {
                                      setConfirmationDialogData({
                                        isFreeDessertCustomer:
                                          user.isFreeDessertCustomer,
                                        type: 'cancel',
                                      })

                                      track(events.TAPS_CANCEL_MEAL_PLAN)
                                    }}
                                    size="small"
                                  >
                                    Cancel Plan
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}

                          <SaveChangesButton
                            handleSubmit={handleSubmit}
                            isSubscriptionActive={isSubscriptionActive}
                            nextStartDate={nextStartDate}
                            onSaved={() => {
                              close()
                            }}
                            planPreferences={planPreferences}
                            user={user}
                            userSubscriptionTypes={userSubscriptionTypes}
                          />
                        </div>

                        {confirmationDialogData && (
                          <PauseCancelConfirmation
                            heading={
                              confirmationDialogData.type === 'cancel' &&
                              confirmationDialogData.isFreeDessertCustomer
                                ? 'Are you sure you want to cancel?'
                                : 'Would you like to skip deliveries instead?'
                            }
                            onClickSkipAFewDeliveries={() => {
                              onClosePauseCancelConfirmationModal()
                              close()

                              onClickSkipAFewDeliveries()
                            }}
                            onCloseModal={() => {
                              onClosePauseCancelConfirmationModal()
                            }}
                            onSubscriptionStatusChanged={() => {
                              openTypeformPopup()

                              onClosePauseCancelConfirmationModal()
                              close()

                              onSubscriptionStatusChanged()
                            }}
                            planChangeType={confirmationDialogData.type}
                          >
                            {confirmationDialogData.type === 'cancel' &&
                            confirmationDialogData.isFreeDessertCustomer ? (
                              <CancelFreeDessertBody />
                            ) : (
                              <PauseCancelBody
                                planChangeType={confirmationDialogData.type}
                                userID={user.id}
                              />
                            )}
                          </PauseCancelConfirmation>
                        )}
                      </>
                    )
                  }}
                </CollapsibleContent>
              </>
            )
          }}
        </Collapsible>
      </div>
    </form>
  )
}

const SaveChangesButton = ({
  handleSubmit,
  isSubscriptionActive,
  nextStartDate,
  onSaved,
  planPreferences,
  user,
  userSubscriptionTypes,
}: {
  handleSubmit: UseFormHandleSubmit<FormData>
  isSubscriptionActive: boolean
  nextStartDate: string
  onSaved(): void
  planPreferences: SubscriptionPreferences | null
  user: UserV1
  userSubscriptionTypes: UserSubscriptionType[]
}) => {
  const navigate = useNavigate()
  const { openToast } = useToast()

  const { data: customer } = useCustomer({
    customerID: user.subscription.customerID,
    userID: user.id,
  })

  const [showMealPlanChangesConfirmation, setShowMealPlanChangesConfirmation] =
    useState(false)

  const {
    error: changeSubscriptionStatusError,
    isError: hasChangeSubscriptionStatusError,
    isLoading: isChangingSubscriptionStatus,
    mutate: changeSubscriptionStatus,
  } = useChangeSubscriptionStatus({
    onSuccess: (...args) => {
      const { reactivationDate } = args[1]

      if (location.pathname === '/account/edit-plan') {
        navigate('/account')
      } else {
        onSaved()
        setShowMealPlanChangesConfirmation(false)
      }

      const message = reactivationDate
        ? `Changes go into effect starting the week of ${formatDate(
            reactivationDate,
            { format: DATE_FORMATS.MONTH_ABBR_DAY }
          )}.`
        : ' Please double check any pending orders.'

      openToast({
        heading: 'Meal Plan Updated',
        message,
        type: 'success',
      })

      if (user.subscription.status === 'paused') {
        track(events.ORDER_RESUME_PLAN_CTA, {
          source_id: sourceIDs.SETTINGS,
        })
      }
    },
  })

  const { mutate: updatePlanPreferences } = useUpdatePlanPreferences()

  const handleUpdateMealPlan = (values: FormData) => {
    const hasShippingInfo = !!user.shippingAddresses[0]?.line1
    // If we don't yet have a customer, we may have encountered a network blip or
    // perhaps the customer call is taking too long to resolve. In either case, we
    // don't want a frontend loading issue to prevent potential reactivation so we
    // assume they do have a payment source.
    const hasPaymentSource = customer ? customer.sources.data.length > 0 : true

    if (!isSubscriptionActive && (!hasShippingInfo || !hasPaymentSource)) {
      openToast({
        goodErrorMessaging: {
          helpToFix: 'Please add the required information and try again.',
          wayOut: null,
          whatHappened: 'Unable to activate plan',
          why: 'You cannot activate a meal plan without shipping and payment information.',
        },
        type: 'error',
      })

      return null
    }

    if (!showMealPlanChangesConfirmation) {
      setShowMealPlanChangesConfirmation(true)
    } else {
      const selectedSubscription = userSubscriptionTypes.find(
        (type) => type.id === values.subscriptionTypeID
      )

      if (
        planPreferences &&
        values.shipPeriod !== `${planPreferences.defaultShipPeriod}`
      ) {
        const updatedPreferences = { ...planPreferences }
        updatedPreferences.defaultShipPeriod = Number.parseInt(
          values.shipPeriod,
          10
        )

        updatePlanPreferences({ data: updatedPreferences, userID: user.id })
      }

      changeSubscriptionStatus({
        data: {
          defaultShipPeriod: Number.parseInt(values.shipPeriod, 10),
          subscriptionTypeID: selectedSubscription
            ? selectedSubscription.id
            : '',
        },
        reactivationDate:
          user.subscription.status === 'active' ? '' : nextStartDate,
        subscriptionStatus: 'activate',
        userID: user.id,
      })
    }
  }

  return (
    <>
      <div className="mt-6 md:fixed md:bottom-4 md:left-0 md:right-0 md:flex md:justify-center">
        <Button
          onClick={(event) => {
            handleSubmit((values) => handleUpdateMealPlan(values))(event)
          }}
          size="large"
          type="submit"
        >
          Save Changes
        </Button>
      </div>

      {showMealPlanChangesConfirmation && (
        <MealPlanChangesConfirmation
          changeSubscriptionError={
            hasChangeSubscriptionStatusError
              ? changeSubscriptionStatusError
              : null
          }
          isChangingSubscription={isChangingSubscriptionStatus}
          onClickClose={() => setShowMealPlanChangesConfirmation(false)}
          onClickConfirm={(event) => {
            handleSubmit((values) => handleUpdateMealPlan(values))(event)
          }}
        />
      )}
    </>
  )
}

export const MealPlanChangesConfirmation = ({
  changeSubscriptionError,
  isChangingSubscription,
  onClickClose,
  onClickConfirm,
}: {
  changeSubscriptionError: Error | null
  isChangingSubscription: boolean
  onClickClose(): void
  onClickConfirm(event: MouseEvent<HTMLButtonElement>): void
}) => {
  return (
    <ConfirmationDialog onRequestClose={onClickClose}>
      <ConfirmationHeader heading="Meal Plan Changes" />
      <ConfirmationBody>
        <div className="py-16 md:py-12">
          <p className="mx-auto max-w-sm text-body-lg">
            Editing your meal plan affects any deliveries that haven't been
            processed, skipped, or had their delivery size changed.
          </p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="max-w-sm space-y-4">
          {changeSubscriptionError && (
            <ErrorDisplay
              helpToFix="Please make sure you have payment and shipping information added on the Account page."
              sentryError={changeSubscriptionError}
              why="We couldn't change your meal plan due to a technical issue on our end."
            />
          )}

          <div className="grid grid-cols-2 gap-2">
            <Button buttonStyle="stroke" onClick={onClickClose} size="large">
              Cancel
            </Button>

            <ButtonLoading
              isLoading={isChangingSubscription}
              onClick={onClickConfirm}
              size="large"
            >
              Save
            </ButtonLoading>
          </div>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}
