import Analytics from 'analytics'
import { JWTPayload, UserV1 } from '@tovala/browser-apis-combinedapi'
import { mapValues } from 'lodash-es'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Can't find types for this plugin right now.
import segmentPlugin from '@analytics/segment'
import * as Sentry from '@sentry/react'

import { AnalyticsEvent } from 'analytics/events'
import { getEnvVar } from './env'

export interface IdentifyUserProperties {
  isThriveUser?: boolean
}

const CODE_VERSION = getEnvVar('VERCEL_GIT_COMMIT_SHA')

const analytics = Analytics({
  app: 'mytovala',
  plugins:
    getEnvVar('APP_ENV') === 'test'
      ? []
      : [
          segmentPlugin({
            writeKey: getEnvVar('SEGMENT_WRITE_KEY'),
          }),
        ],
})

export function coerceValuesToStrings(
  obj: Record<string, number | string | undefined>
) {
  return mapValues(obj, (value) => {
    if (typeof value === 'number') {
      return `${value}`
    } else if (value === undefined) {
      return ''
    }

    return value
  })
}

export function identifyUser({
  user,
  userProperties,
}: {
  user: UserV1
  userProperties: IdentifyUserProperties
}): void {
  analytics.identify(`${user.id}`, {
    email: user.info.email,
    name: user.info.name,
    system: 'mytovala',
    version: CODE_VERSION,
    ...userProperties,
  })

  Sentry.configureScope((scope) => {
    scope.setUser({
      email: user.info.email,
      name: user.info.name,
      id: `${user.id}`,
      version: CODE_VERSION,
    })
  })
}

export function identifyUserFromJWT({
  decodedJWT,
  userProperties,
}: {
  decodedJWT: JWTPayload
  userProperties: IdentifyUserProperties
}): void {
  analytics.identify(`${decodedJWT.userId}`, {
    system: 'mytovala',
    version: CODE_VERSION,
    ...userProperties,
  })

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: `${decodedJWT.userId}`,
      version: CODE_VERSION,
    })
  })
}

export function identifyRetailChannel({
  userID,
  retailChannel,
  location,
  phoneNumber = '',
}: {
  userID: number
  retailChannel: string
  location: string
  phoneNumber?: string
}): void {
  analytics.identify(`${userID}`, {
    retailChannel,
    retailLocation: location,
    retailPhone: phoneNumber,
  })
}

export function page() {
  analytics.page()
}

export function track(
  eventName: AnalyticsEvent,
  eventProps?: Record<string, number | string | undefined>
) {
  analytics.track(
    eventName,
    // Note: we want to ensure our attributes are sent as strings to make analysis
    // easier / more consistent. See https://tovala.atlassian.net/browse/WAT-672.
    eventProps ? coerceValuesToStrings(eventProps) : undefined
  )
}
