import { useMenu } from '@tovala/browser-apis-menu-delivery'
import { usePublicTermStatuses } from '@tovala/browser-apis-combinedapi'

import { useUser } from 'contexts/user'
import { useAllMealSummaries } from './combinedAPI/meals'
import { orderBy } from 'lodash-es'

export function usePublicCurrentMenu({
  enabled = true,
}: {
  enabled?: boolean
}) {
  const {
    data: publicTermStatuses = [],
    error: loadPublicTermStatusesError,
    isError: hasLoadPublicTermStatusesError,
  } = usePublicTermStatuses({ enabled })

  const allMealSummaries = useAllMealSummaries({
    enabled,
    subTermIDs: publicTermStatuses
      .filter((term) => term.readyForView)
      .map((term) => term.selectedSubTermID),
  })

  const isLoadingMealSummaries = allMealSummaries.some(
    (mealSummaries) => mealSummaries.isLoading
  )
  // On the rare occasion the current term has no meals (holiday break, for example),
  // we want the first term that has meals
  const currentMenuIndex = !isLoadingMealSummaries
    ? allMealSummaries.findIndex(
        (mealSummaries) => mealSummaries.data && mealSummaries.data.length > 0
      )
    : -1

  const mealSummaries =
    currentMenuIndex > -1 ? allMealSummaries[currentMenuIndex]?.data : []

  return {
    error: loadPublicTermStatusesError,
    isError: hasLoadPublicTermStatusesError,
    mealSummaries: orderBy(mealSummaries, (meal) => meal.mainDisplayOrder),
    term:
      currentMenuIndex > -1 ? publicTermStatuses[currentMenuIndex] : undefined,
  }
}

export function useMenuComponents({ subTermID }: { subTermID: string }) {
  const { user } = useUser()

  const { data: menuComponents, isLoading: isLoadingMenu } = useMenu({
    subTermID,
    userID: user.id,
  })

  return {
    components: menuComponents?.components ?? [],
    isLoading: isLoadingMenu,
  }
}
