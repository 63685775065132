import { UserV1 } from '@tovala/browser-apis-combinedapi'
import { clsx } from 'clsx'
import Link from 'components/common/Link'

const ActiveRewards = ({
  cardStyle,
  user,
}: {
  cardStyle: 'white' | 'grey'
  user: UserV1
}) => {
  const rewards = getActiveRewards(user)

  if (rewards.length === 0) {
    return null
  }

  return (
    <div className="space-y-4">
      <h3 className="text-k/20_110">Active Rewards</h3>
      <div>
        {rewards.map((reward) => {
          return (
            <div key={reward}>
              {reward === 'isFreeDessertCustomer' ? (
                <div
                  className={clsx('flex gap-x-3 rounded-xl p-3 text-k/14_120', {
                    'bg-grey-2': cardStyle === 'grey',
                    'bg-white': cardStyle === 'white',
                  })}
                >
                  <div className="h-4 w-6 shrink-0 text-orange-1">
                    <CakeSliceIcon />
                  </div>
                  <div className="grow space-y-4">
                    <div>
                      <h4 className="font-semibold">Free Dessert for Life!</h4>
                      <p>
                        Add a dessert and get it free. Discount applied when
                        order closes.
                      </p>
                    </div>
                    <div className="max-w-[156px]">
                      <Link
                        linkStyle="stroke"
                        rel="noopener noreferrer"
                        size="small"
                        target="_blank"
                        to="/legal/free-dessert-terms"
                      >
                        Terms & Conditions
                      </Link>
                    </div>
                  </div>
                  <div className="justify-end font-bold">FREE</div>
                </div>
              ) : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ActiveRewards

const CakeSliceIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.58332 4.83341C8.58332 3.22258 9.88916 1.91675 11.5 1.91675H12.75M26.0834 18.5834L26.0834 17.5281C26.0833 15.7762 24.7271 14.3232 22.9794 14.2027L1.91666 12.7501L1.9167 18.5834M26.0834 18.5834L26.0834 24.4167C26.0834 25.3372 25.3372 26.0834 24.4168 26.0834H3.58342C2.66296 26.0834 1.91677 25.3372 1.91676 24.4168L1.9167 18.5834M26.0834 18.5834H1.9167M12.75 9.00008C12.75 11.0711 11.0711 12.7501 8.99999 12.7501C6.92892 12.7501 5.24999 11.0711 5.24999 9.00008C5.24999 6.92901 6.92892 5.25008 8.99999 5.25008C11.0711 5.25008 12.75 6.92901 12.75 9.00008Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  )
}

export function getActiveRewards(user: UserV1) {
  const rewards: string[] = []

  if (user.isFreeDessertCustomer) {
    rewards.push('isFreeDessertCustomer')
  }

  return rewards
}
