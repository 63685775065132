import { ReactNode } from 'react'
import { MenuComponentsStandardized } from '@tovala/browser-apis-menu-components'
import { getFilteredComponentsCounts } from 'utils/menus'

const MenuMeals = ({
  filters,
  mealGroups,
  menuComponentsGrid,
}: {
  filters?: ReactNode
  mealGroups: {
    components: MenuComponentsStandardized
    description: string | null
    id: 'filtered' | 'everythingElse'
    title: string | null
  }[]
  menuComponentsGrid(opts: {
    components: MenuComponentsStandardized
  }): ReactNode
}) => {
  return (
    <div>
      {mealGroups.map(({ components, description, id, title }) => {
        return (
          <MealGroup key={id}>
            <div className="mx-auto max-w-menu lg:px-4 md:px-0">
              <div className="space-y-10 md:space-y-6">
                <MealGroupTitle
                  description={description}
                  filters={filters && id === 'filtered' ? filters : null}
                  quantityResults={
                    id === 'filtered'
                      ? getFilteredComponentsCounts({
                          components,
                        })
                      : null
                  }
                  title={title}
                />

                {menuComponentsGrid({ components })}
              </div>
            </div>
          </MealGroup>
        )
      })}
    </div>
  )
}

export default MenuMeals

const MealGroup = ({ children }: { children: ReactNode }) => {
  return (
    <div className="py-10 first:pt-0 last:pb-0 even:bg-white even:pb-10 md:py-6">
      {children}
    </div>
  )
}

const MealGroupTitle = ({
  description,
  filters,
  quantityResults,
  title,
}: {
  description: string | null
  filters: ReactNode
  quantityResults: number | null
  title: string | null
}) => {
  if (!title) {
    return null
  }

  return (
    <div className="space-y-3 md:px-4">
      <div className="flex items-center justify-between">
        <h2 className="text-k/36_110 md:text-k/26_110">{title}</h2>

        {filters}
      </div>

      <div className="space-y-10">
        {quantityResults !== null && (
          <p className="text-k/20_125 text-grey-9 md:text-k/18_120">
            {quantityResults} results
          </p>
        )}

        {description && (
          <div className="text-k/20_125 text-grey-9 md:text-k/18_120">
            {description}
          </div>
        )}
      </div>
    </div>
  )
}
