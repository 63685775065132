import { Button, XIcon } from '@tovala/component-library'
import { Dialog } from '@headlessui/react'
import { ReactNode } from 'react'

const Modal = ({
  children,
  onCloseModal,
}: {
  children: ReactNode
  onCloseModal: () => void
}): JSX.Element => {
  return (
    <Dialog
      onClose={() => {
        onCloseModal()
      }}
      open={true}
    >
      <div
        className="fixed inset-0 z-30 h-full w-full bg-black opacity-40"
        onClick={onCloseModal}
      />
      <div className="fixed inset-0 z-30 flex h-full max-h-screen w-full items-center justify-center overflow-auto md:items-end">
        <Dialog.Panel className="max-h-screen overflow-y-auto rounded-lg bg-white md:m-1">
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Modal

export const ModalBody = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return <div>{children}</div>
}

export const ModalHeader = ({
  children,
  onClickClose,
}: {
  children: ReactNode
  onClickClose?: () => void
}): JSX.Element => {
  return (
    <div className="relative border-b border-grey-3 px-12 py-6">
      <Dialog.Title className="text-center text-k/16_125 uppercase">
        {children}
      </Dialog.Title>
      {onClickClose && (
        <div className="absolute right-6 top-1/2 h-6 w-6 -translate-y-1/2">
          <Button
            aria-label="Close"
            buttonStyle="link"
            onClick={onClickClose}
            size="auto"
          >
            <div className="h-6 w-6">
              <XIcon />
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}
