import { ReactNode } from 'react'
import { Button, ButtonLoading } from '@tovala/component-library'
import { useUserTermStatuses } from '@tovala/browser-apis-combinedapi'
import { DATE_FORMATS, formatDate } from 'utils/dates'
import { ErrorCodeMessageMapCombinedAPI } from 'types/internal'
import { events } from '../../../analytics/events'
import { isCombinedAPIResponseError } from 'utils/api'
import { track } from 'utils/analytics'

import { useChangeSubscriptionStatus } from 'hooks/combinedAPI/subscriptions'
import { useScheduledDelivery } from 'hooks/orderHistory'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ConfirmationDialog, {
  ConfirmationBody,
  ConfirmationButtons,
  ConfirmationHeader,
} from '../../common/ConfirmationDialog'

const CHANGE_SUBSCRIPTION_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't update your subscription due to a technical issue on our end.",
  },
}

const PauseCancelConfirmation = ({
  children,
  heading,
  onClickSkipAFewDeliveries,
  onCloseModal,
  onSubscriptionStatusChanged,
  planChangeType,
}: {
  children: ReactNode
  heading: string
  onClickSkipAFewDeliveries(): void
  onCloseModal(): void
  onSubscriptionStatusChanged(): void
  planChangeType: 'cancel' | 'pause'
}) => {
  const { user } = useUser()

  const {
    error: changeSubscriptionStatusError,
    isError: hasChangeSubscriptionStatusError,
    isLoading: isChangingSubscriptionStatus,
    mutate: changeSubscriptionStatus,
  } = useChangeSubscriptionStatus({
    onError: (err) => {
      /*
        If subscription is already paused/canceled (via Glaze, for example), our user queries
        will be refreshed, and we'll show the user a success message
      */
      if (
        isCombinedAPIResponseError(err) &&
        err.response?.data.message ===
          'UnableToUpdateSubscriptionToExistingState'
      ) {
        onSubscriptionStatusChanged()
      }
    },
    onSuccess: () => {
      onSubscriptionStatusChanged()
    },
  })

  const handlePlanChange = () => {
    if (!user.subscription.subscriptionType) {
      return
    }

    changeSubscriptionStatus({
      data: {
        defaultShipPeriod: user.subscription.defaultShipPeriod,
        subscriptionTypeID: user.subscription.subscriptionType.id,
      },
      subscriptionStatus: planChangeType,
      userID: user.id,
    })
  }

  return (
    <ConfirmationDialog
      onRequestClose={() => {
        onCloseModal()
      }}
    >
      <ConfirmationHeader
        heading={heading}
        onClickClose={() => {
          onCloseModal()
        }}
      />
      <ConfirmationBody>
        <div className="flex flex-col items-center py-10">
          <p className="mx-auto mb-6 max-w-sm text-body-lg">{children}</p>
        </div>
      </ConfirmationBody>
      <ConfirmationButtons>
        <div className="space-y-4">
          {hasChangeSubscriptionStatusError && (
            <APIErrorDisplay
              error={changeSubscriptionStatusError}
              errorCodeMessageMap={CHANGE_SUBSCRIPTION_ERRORS}
            />
          )}

          <div className="grid grid-cols-2 gap-2">
            <ButtonLoading
              buttonStyle="stroke"
              isLoading={isChangingSubscriptionStatus}
              onClick={() => {
                handlePlanChange()
              }}
              size="large"
            >
              <span className="capitalize">{planChangeType} Meal Plan</span>
            </ButtonLoading>

            <Button
              onClick={() => {
                track(events.MANAGE_DELIVERIES)
                onClickSkipAFewDeliveries()
              }}
              size="large"
              type="submit"
            >
              Skip A Few Deliveries
            </Button>
          </div>
        </div>
      </ConfirmationButtons>
    </ConfirmationDialog>
  )
}

export default PauseCancelConfirmation

export const PauseCancelBody = ({
  planChangeType,
  userID,
}: {
  planChangeType: 'cancel' | 'pause'
  userID: number
}) => {
  const { scheduledDelivery } = useScheduledDelivery({ userID })

  const { data: termStatuses } = useUserTermStatuses({ userID })

  const presentUserTerm = termStatuses ? termStatuses[0] : ''
  return (
    <>
      This will {planChangeType} your meals that were set to arrive{' '}
      {presentUserTerm ? (
        <span>
          the week of{' '}
          <strong>
            {formatDate(presentUserTerm.startDate, {
              format: DATE_FORMATS.DOW_MONTH_ABBR_DAY,
            })}
          </strong>
        </span>
      ) : (
        'next'
      )}
      , as well as all future deliveries.{' '}
      {scheduledDelivery && (
        <span>
          If you have a delivery scheduled for{' '}
          <strong>
            {formatDate(scheduledDelivery.userTermOrderSummary.deliveryDate, {
              format: DATE_FORMATS.DOW_MONTH_ABBR_DAY,
            })}
          </strong>
          , it will still be on its way.
        </span>
      )}
    </>
  )
}

export const CancelFreeDessertBody = () => {
  return (
    <div className="py-16">
      You sure you want to cancel?
      <br />
      <strong>You'll lose your free dessert for life!</strong>
    </div>
  )
}
