import { Button } from '@tovala/component-library'
import { ReactNode } from 'react'
import { useInvalidateMenus } from '@tovala/browser-apis-menu-delivery'

const MenuError = ({
  menuHeader,
  subTermID,
  userID,
}: {
  menuHeader: ReactNode
  subTermID: string
  userID: number
}) => {
  const { invalidateMenu } = useInvalidateMenus()

  return (
    <div>
      <div className="mx-auto max-w-menu pb-4 pt-10 lg:px-4 md:border-t md:border-grey-3 md:py-4">
        {menuHeader}
      </div>

      <div className="mx-auto w-full max-w-lg px-6 pb-48 pt-20 md:px-4">
        <div className="text-center">
          <h1 className="text-k/36_110 md:text-k/28_110">
            We're sorry, we're having trouble loading our menus right now.
            Please contact customer support if this issue continues.
          </h1>

          <div className="mt-6 flex justify-center space-x-6">
            <Button
              buttonStyle="dark"
              onClick={() => {
                invalidateMenu(userID, subTermID)
              }}
              size="large"
            >
              Try Again
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuError
