import { formatCentsToDollars } from 'utils/currency'

const CreditsBanner = ({
  isFreeDessertCustomer,
  totalCreditsCents,
}: {
  isFreeDessertCustomer: boolean
  totalCreditsCents: number
}) => {
  if (totalCreditsCents === 0 && !isFreeDessertCustomer) {
    return null
  }

  return (
    <div className="bg-[#247C15] p-4 text-center text-white md:p-3">
      {isFreeDessertCustomer ? (
        <>
          {totalCreditsCents > 0 ? (
            <>
              <span>
                {formatCentsToDollars(totalCreditsCents) +
                  ' off your next delivery'}
              </span>
              <span className="font-semibold">
                {' '}
                + add a dessert and get it free!
              </span>
            </>
          ) : (
            'Add a dessert and get it free—for life!'
          )}
        </>
      ) : (
        <span>
          {formatCentsToDollars(totalCreditsCents) + ' off your next delivery'}
        </span>
      )}
    </div>
  )
}

export default CreditsBanner
