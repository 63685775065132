import { MealSummary, MealSwap } from '@tovala/browser-apis-combinedapi'
import {
  getAllMenuComponentsMealIDs,
  getMenuComponentsStandardized,
  makeMenuComponentsStandardized,
  MenuComponentStandardizedFeedback,
  MenuComponentStandardizedSuggestions,
} from '@tovala/browser-apis-menu-components'
import { MenuComponents, useMenu } from '@tovala/browser-apis-menu-delivery'
import { v4 as uuidV4 } from 'uuid'

export function useResolvedMenuComponents({
  enableMenuTargeting,
  mealSuggestionsForMenu,
  mealSummaries,
  mealSwaps,
  subTermID,
  userID,
}: {
  enableMenuTargeting: boolean
  mealSuggestionsForMenu: MealSummary[] | undefined
  mealSummaries: MealSummary[]
  mealSwaps: MealSwap[]
  subTermID: string
  userID: number
}) {
  const {
    data: menuComponents,
    error: loadMenuError,
    isError: hasLoadMenuError,
  } = useMenu({
    subTermID,
    userID,
  })
  const components = menuComponents?.components ?? []

  let resolvedMenuComponents = getMenuComponentsStandardized({
    meals: mealSummaries,
    menuComponents: components,
    suggestions: mealSuggestionsForMenu,
  })

  /**
   * If menu targeting is not enabled, make menu components for any missing meals
   * from meal summaries without MDS menu components so user sees all meals.
   * This will create menu components for all meals if MDS is returning no menu components.
   */
  if (!enableMenuTargeting) {
    const additionalComponents = makeMenuComponentsStandardized({
      meals: hasLoadMenuError
        ? mealSummaries
        : getMealsWithoutMenuComponents({
            menuComponents: components,
            mealSummaries,
          }),
      mealSwaps,
      suggestions: mealSuggestionsForMenu,
      specialEvent: undefined,
    })

    resolvedMenuComponents = [
      ...resolvedMenuComponents,
      ...additionalComponents,
    ]
  }

  // Suggestions are currently not a component supported by MDS -
  // insert suggestions component after textBanner or as first component
  if (resolvedMenuComponents.length > 0 && mealSuggestionsForMenu) {
    const suggestionsComponent: MenuComponentStandardizedSuggestions = {
      id: uuidV4(),
      properties: { suggestions: mealSuggestionsForMenu },
      type: 'suggestions',
    }

    if (resolvedMenuComponents[0].type === 'textBanner') {
      const [textBanner, ...rest] = [...resolvedMenuComponents]
      resolvedMenuComponents = [
        { ...textBanner },
        suggestionsComponent,
        ...rest,
      ]
    } else {
      resolvedMenuComponents = [suggestionsComponent, ...resolvedMenuComponents]
    }
  }

  // UI Editor does not currently add a feedback component so we insert one
  // at the end of the list of components
  if (resolvedMenuComponents.length > 0) {
    const feedbackComponent: MenuComponentStandardizedFeedback = {
      id: uuidV4(),
      properties: {
        options: [
          { label: '😠', value: 1 },
          { label: '😕', value: 2 },
          { label: '😐', value: 3 },
          { label: '😋', value: 4 },
          { label: '😍', value: 5 },
        ],
        subtitle: 'All feedback helps us improve our future menus',
        tableID: 'menu_feedback',
        title: 'How would you rate the menu for this week?',
      },
      type: 'menuFeedback' as const,
    }
    resolvedMenuComponents.push(feedbackComponent)
  }

  return {
    resolvedMenuComponents,
    loadMenuError: enableMenuTargeting ? loadMenuError : null,
  }
}

function getMealsWithoutMenuComponents({
  menuComponents,
  mealSummaries,
}: {
  menuComponents: MenuComponents['components']
  mealSummaries: MealSummary[]
}) {
  const menuComponentsMealIDs = getAllMenuComponentsMealIDs({
    menuComponents,
  })

  return mealSummaries.filter((meal) => {
    return !menuComponentsMealIDs.has(meal.id)
  })
}
